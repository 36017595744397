import React, { useEffect, useRef } from "react";
import LocationItem from "../locationItem/locationItem";
import KioskPlacement from "../ecoATMComponents/kioskPlacements";
import "./locationListItems.scss";
import { SEARCH_RES_LOG_TYPE, EVENT_LOG_TYPE } from "../../utils/constants";
import { useTracking } from "react-tracking";

const LocationListItems = ({
  locations,
  locationSelected = 0,
  onClick,
  showAccordion,
  parentContainerClass,
  itemContainerClass,
  showLandingPageLink,
  couponButton,
  onCouponClick,
  getContainerSize,
}) => {
  const getDaysArray = (dailyHoursList) => {
    return dailyHoursList.map((hour) => {
      return {
        day: hour.NameOfDay.substring(0, 3),
        hours: hour.NoHoursAvailable ? "Close" : hour.HoursDisplayText,
        isClosed: hour.NoHoursAvailable,
        closeTime: hour.ClosingTime,
        openTime: hour.OpeningTime,
      };
    });
  };

  const parentListContainer = useRef();
  const listItemsRef = useRef([]);

  function scrollParentToChild(parent, child) {
    // Where is the parent on page
    var parentRect = parent.getBoundingClientRect();
    // What can you see?
    var parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth,
    };

    // Where is the child
    var childRect = child.getBoundingClientRect();
    // Is the child viewable?
    var isViewable =
      childRect.top >= parentRect.top &&
      childRect.top <= parentRect.top + parentViewableArea.height;

    // if you can't see the child try to scroll parent
    if (!isViewable) {
      // scroll by offset relative to parent
      parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top;
    }
  }

  useEffect(() => {
    // report height size
    if (getContainerSize) {
      getContainerSize(parentListContainer.current.offsetHeight);
    }
  }, [locations, getContainerSize]);

  useEffect(() => {
    if (locationSelected > 0 && listItemsRef) {
      let element = listItemsRef.current[locationSelected];
      if (element) {
        element.classList.add("locationListItems__item-active");
        element.scrollTop = 10;
        scrollParentToChild(parentListContainer.current, element);
      }
    } //else parentListContainer.current.scrollTo(0, 0);
  }, [locationSelected, locations]);

  const { trackEvent } = useTracking();

  return (
    <>
      <div ref={parentListContainer} className={parentContainerClass || ""}>
        {locations.length > 0 &&
          locations.map(
            ({
              Name,
              Address1,
              StateAbbr,
              State,
              City,
              PostCode,
              Id,
              DailyHoursList,
              URL,
              Distance,
              openCloseInfo,
              kiosks,
              PhoneNumber,
              ThirdPartyId,
            }) => {
              return (
                <div className={itemContainerClass || ""} key={Id}>
                  <div
                    ref={(el) => (listItemsRef.current[Id] = el)}
                    className={`card shadow-eco p-3 rounded-1 locationListItems__item mb-3 w-100 ${
                      locationSelected === Id
                        ? " locationListItems__item-active "
                        : ""
                    }`}
                    onClick={() => {
                      trackEvent({
                        eventType: EVENT_LOG_TYPE.logSearch,
                        locationId: Id,
                        responseType:
                          SEARCH_RES_LOG_TYPE.locationResultsClicked,
                      });

                      if (onClick) {
                        onClick(Id);
                      }
                    }}
                  >
                    <LocationItem
                      showLandingPageLink={showLandingPageLink}
                      locationId={Id}
                      locationName={Name}
                      address1={Address1}
                      daysArray={getDaysArray(DailyHoursList)}
                      stateAbbr={State ? State : StateAbbr}
                      city={City}
                      postCode={PostCode}
                      // country={CountryName}
                      extraInformation={<KioskPlacement kiosksArray={kiosks} />}
                      showAccordion={showAccordion}
                      landingPageURL={`/${State?.toLowerCase()}/${City?.toLowerCase().replace(
                        / /g,
                        "-"
                      )}/sell-my-phone-${City?.toLowerCase()?.replace(
                        / /g,
                        ""
                      )}-${State?.toLowerCase()}-${ThirdPartyId?.toLowerCase()}.html`}
                      distance={Distance}
                      openCloseInfo={openCloseInfo}
                      coupon={
                        couponButton &&
                        React.cloneElement(couponButton, {
                          onClick: () => {
                            onCouponClick(
                              Name,
                              Address1,
                              State,
                              City,
                              PostCode,
                              PhoneNumber,
                              Id
                            );
                          },
                        })
                      }
                    />
                  </div>
                </div>
              );
            }
          )}
      </div>
    </>
  );
};

export default LocationListItems;
